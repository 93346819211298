// ------------------------------------------------- //
// ----            --------------------------------- //
// ------------------------------------------------- //
@import './vendors/reset.less';

// ------------------------------------------------- //
// ----            --------------------------------- //
// ------------------------------------------------- //
@import './theme/colors.less';
@import './theme/sizes.less';
@import './theme/animations.less';
@import './theme/inputs.less';

// ------------------------------------------------- //
// ----            --------------------------------- //
// ------------------------------------------------- //
@import './fonts/poppings.less';

// ------------------------------------------------- //
// ----            --------------------------------- //
// ------------------------------------------------- //
@import './layout/rows.less';
@import './layout/cols.less';
@import './layout/pages.less';
@import './layout/campaign-list.less';

app-root {
  display: block;
  zoom: 0.8;
  height: 100vh;
}
.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}
.swiper-pagination-bullet {
  display: block;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  width: initial;
  top: 34%;
  right: 0;
  bottom: inherit;
  left: inherit;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-pagination-bullet-active {
  --swiper-pagination-color: white;
  --swiper-theme-color: white;
  background: 
  var(--swiper-pagination-color, 
  var(--swiper-theme-color)
  );
}
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.ng-bar-placeholder {
  height: 16px !important;
}

:host ::ng-deep #table {
  font-family: 'Montserrat', sans-serif;
}

.autocomplete-container {
  box-shadow: none;
  border: 2px solid #58b999;
  border-radius: 0.6em;
  input {
    border-radius: 1em;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.autocomplete-container.active {
  box-shadow: none;
  border: 2px solid #58b999;
  background-color: rgba(0, 0, 0, 0) !important;
}
.autocomplete-container input {
  z-index: 1000;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0) !important;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  position: inherit !important;
  width: 100% !important;

  .swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    text-align: center;
    line-height: 20px !important;
    font-size: 12px;
    opacity: 1;
    border-radius: 100%;
    background: #ffffff6e;
    margin: 3px;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    color: white;
    background: #ffffffcc !important;
    transform: scale(1);
  }
}

.cover .swiper-pagination-bullets {
  top: -80px !important;
}

.page-campaign .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  transform: rotate(90deg);
  position: absolute;
  left: calc(50% - 25px) !important;
  top: calc(-50% - 0px) !important;
}

.cover .swiper-pagination-bullet {
  border: 1px solid rgba(255, 255, 255, 0.753) !important;
}

// .ngx-table{
//   font-family: 'Poppins-Regular' !important;
//   text-align: center;
// }

.ngx-table__table--normal > tbody > tr > td {
  border-bottom: 1px solid #e7e9ed;
  padding: 10px 4px !important;
  border-left: 1px solid #62CEAA;
  border-right: 1px solid #62CEAA;
  border-top: 2px solid #62CEAA;
}

.ngx-table__table--normal > tbody > tr > th {
  border:none !important;
  border:0px solid white !important;
  padding: 0px 6px !important;
}


.ngx-table__header-cell{
  border:0px solid white !important;
  padding: 0px 6px !important;
}
.ngx-table__sort-header th{
  border:0px solid white !important;
  padding: 0px 6px !important;
}

.ngx-table__table-row--selected td{
  background-color: #EAFDF7!important;
  background:#EAFDF7!important;
}


.ngx-table__table-row--selected td{
  background-color: #EAFDF7!important;
  background:#EAFDF7!important;
}

element.style {
  width: 10px;
}


label[for="search_date_end"]{
  width: 100%;
}




::ng-host .campaigns-sortby-wrapper .dropdown-input{
  padding-bottom: 0px !important;
}

.campaigns-sortby-wrapper ui-dropdown-input .dropdown-input{
  padding-bottom: 0px !important;
}


.ce-block__content{
  //width: 100% !important;
  //max-width: 100% !important;
}
.ce-block__content, .ce-toolbar__content { max-width:calc(100% - 80px) !important; } .cdx-block { max-width: 100% !important; }
.codex-editor{
  position: relative;
  right: 39px;
}


































